
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'WebcamSourceSelectionMenu',
  props: ['videoDeviceId', 'audioDeviceId'],
  emits: ['update:videoDeviceId', 'update:audioDeviceId', 'startWebcamClicked'],
  setup(props, { emit }) {
    const videoDevices = ref([] as MediaDeviceInfo[]);
    const audioDevices = ref([] as MediaDeviceInfo[]);
    const state = ref('unloaded' as 'unloaded' | 'loading' | 'loaded');
    const error = ref(null as any | null);

    const selectVideoDevice = (id: string) => {
      emit('update:videoDeviceId', id);
    };
    const selectAudioDevice = (id: string) => {
      emit('update:audioDeviceId', id);
    };

    const loadDevices = async () => {
      try {
        error.value = null;
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

        const devices = await navigator.mediaDevices.enumerateDevices();
        videoDevices.value = devices.filter((device) => device.kind === 'videoinput');
        audioDevices.value = devices.filter((device) => device.kind === 'audioinput');

        // Autoselect the first devices
        if (videoDevices.value.length > 0) {
          selectVideoDevice(videoDevices.value[0].deviceId);
        }
        if (audioDevices.value.length > 0) {
          selectAudioDevice(audioDevices.value[0].deviceId);
        }

        state.value = 'loaded';
      } catch (e) {
        state.value = 'unloaded';
        error.value = e;
      }
    };

    return {
      state,
      error,
      videoDevices,
      audioDevices,
      loadDevices,
      selectVideoDevice,
      selectAudioDevice,
    };
  },
});
