

import { defineComponent } from 'vue';

export type Instruction = {
  id: number;
  text: string;
}

export default defineComponent({
  props: {
    instructions: {
      type: Array,
      default: Array,
    },
    sizeClass: {
      type: String,
      default: 'is-normal',
    },
    tagClass: {
      type: String,
      default: 'is-dark',
    },
  },
});
