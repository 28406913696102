
import { defineComponent } from 'vue';

const Status = Object.freeze({
  Initial: 'Initial',
  Saving: 'Saving',
  Success: 'Success',
  Failed: 'Failed',
});

export default defineComponent({
  name: 'UploadCard',
  props: {
    uploadAccept: {
      type: String,
      default: '*',
    },
    uploadInstructions: {
      type: String,
      default: 'Click here or drag your file(s) over this area',
    },
    savingText: {
      type: String,
      default: 'Processing File(s)...',
    },
    onFilesSelected: {
      type: Function,
      default: () => {},
    },
    successText: {
      type: String,
      default: 'Files uploaded successfully',
    },
  },
  data() {
    return {
      uploadError: null,
      currentStatus: null as null | string,
      uploadFieldName: 'files',
    };
  },
  emits: ['cancelled', 'done'],
  computed: {
    isInitial() { return (this as any).currentStatus === Status.Initial; },
    isSaving() { return (this as any).currentStatus === Status.Saving; },
    isSuccess() { return (this as any).currentStatus === Status.Success; },
    isFailed() { return (this as any).currentStatus === Status.Failed; },
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = Status.Initial;
      this.uploadError = null;
    },
    async save(fileList: FileList) {
      // upload data to the server
      this.currentStatus = Status.Saving;

      try {
        const result = await this.onFilesSelected(fileList);
        if (result) {
          this.$emit('done');
          this.currentStatus = Status.Success;
        } else {
          this.currentStatus = Status.Initial;
        }
      } catch (e) {
        this.currentStatus = Status.Failed;
        this.uploadError = e as any;
      }
    },
  },
  mounted() {
    this.reset();
  },
});
